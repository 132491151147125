@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* #eeeeec */
    /* --background: 60 20% 99%; */
    --background: 0 0% 100%;
    /* #111110 */
    --foreground: 60 3% 6%;

    /* #FEFEFD */
    --card: 60 33% 99%;
    --card-foreground: 60 3% 6%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    /* #eeeeec */
    --primary: 60 6% 93%;
    /* #111110 */
    --primary-foreground: 60 3% 6%;

    --secondary: 0 0% 95%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    /* #F8ED91 yellow */
    --accentLemon: 54 88% 77%;
    /* #FFDA7A yellow */
    --accent: 43 100% 74%;

    /* #8873f0 violet */
    /* --accent-violet: 250 81% 70%; */
    /* #9663FC */
    --accent-violet: 260 96% 69%;
    /* #B9F2FE blue clair */
    --accent-blue: 190 97% 86%;
    /* #ff8c5b accent orange */
    --accent-orangeTest: 18 100% 68%;
    /* #ff8c5b accent orange */
    --accent-orange: 28 100% 62%;

    /* #111110 */
    --accent-foreground: 60 3% 6%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;

    /* custom Alert */
    --success-foreground: 142 71% 45%;
    --danger-foreground: 18 100% 68%;
    --info-foreground: 190 97% 86%;
    /* side bar */
    --sidebar-background: var(--background);
    --sidebar-foreground: var(--foreground);
    --sidebar-primary: var(--primary);
    --sidebar-primary-foreground: var(--primary-foreground);
    --sidebar-accent: var(--accent);
    --sidebar-accent-foreground: var(--accent-foreground);
    --sidebar-border: var(--border);
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    /* #111110 */
    --background: 60 3% 6%;
    /* #eeeeec */
    --foreground: 60 6% 93%;

    /* #0e0e0c */
    --card: 60 8% 5%;
    /* #eeeeec */
    --card-foreground: 60 6% 93%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    /* #111110 */
    --primary: 60 6% 93%;
    /* #eeeeec */
    --primary-foreground: 60 3% 6%;
    /* #1f1f1e */
    --secondary: 60 2% 12%;
    --secondary-foreground: 0 0% 98%;

    /* #0e0e0c ??? */
    --muted: 60 8% 5%;
    /* #b5b3ad */
    --muted-foreground: 45 5% 69%;

    /* #CBBF82 yellow */
    /* --accent: 50 41% 65%; */

    /* #FCDA69 */
    --accent: 46 96% 70%;
    /* #111110 */
    --accent-foreground: 60 3% 6%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    /* #1f1f1e */
    --border: 60 2% 12%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    /* side bar */
    --sidebar-background: var(--muted);
    --sidebar-foreground: var(--foreground);
    --sidebar-primary: var(--primary);
    --sidebar-primary-foreground: var(--primary-foreground);
    --sidebar-accent: var(--accent);
    --sidebar-accent-foreground: var(--accent-foreground);
    --sidebar-border: var(--border);
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  html,
  body {
    scroll-behavior: smooth;
  }
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  /* custom */
  .custom-container {
    @apply w-full mx-auto px-4;
  }
  .website-container {
    @apply container;
  }
  .custom-motion-slide-up {
    @apply motion-preset-slide-up-md;
  }
  .custom-motion-slide-left {
    @apply motion-preset-slide-left-lg;
  }
  .custom-motion-preset-blur-down-lg {
    @apply motion-preset-blur-down-lg;
  }
  .custom-motion-preset-blur-up-lg {
    @apply motion-preset-blur-up-lg motion-delay-200;
  }
}
